@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../../../assets/background-1440.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: 0 24px;
  padding: 0;
  text-align: center;
  position: relative;

  @media (--viewportMedium) {
    margin: 0;
    padding: 0 200px 0 7.5vw;
    text-align: left;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1098px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroIntroTitle {
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightRegular);
  composes: animation;
  margin: 0;
  color: white;
  stroke: white;

  /* @media (--viewportMedium) {
    max-width: 500px;
  } */

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 26px;
  }
}

.heroIntroTitle img {
  margin-left: 4px;
  margin-bottom: 3px;
}

.heroMobileFullTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  font-size: 40px;
  line-height: 50px;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 767px) {
    margin: 8px 40px 0px 40px;
  }
}

.heroDesktopOneTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  font-size: 40px;
  line-height: 50px;
  composes: animation;
  margin-bottom: 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 767px) {
    margin: 8px 0 24px;
  }
}

.heroDesktopTwoTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--marketplaceColor);
  font-size: 40px;
  line-height: 50px;
  composes: animation;
  margin-top: 0;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }

  @media (max-width: 767px) {
    margin: 8px 0 24px;
  }
}

.heroMobileFullTitleFEDelay,
.heroDesktopOneTitleFEDelay,
.heroDesktopTwoTitleFEDelay {
  animation-delay: 0s;
}

.heroImageMobile {
  composes: animation;
  animation-delay: 0.08s;
  margin-bottom: 24px;

  & img {
    max-width: 300px;
    opacity: 0.8;
  }

  @media (--viewportMedium) {
    display: none;
  }
}

.heroSubTitle {
  @apply --marketplaceH3FontStyles;

  color: white;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  composes: animation;

  & > span {
    position: relative;
    top: -82px;

    @media (--viewportMedium) {
      position: initial;
    }
  }

  @media (--viewportMedium) {
    position: initial;
    max-width: 500px;
    margin: 0 0 47px 0;
  }

  @media (max-width: 767px) {
    height: 0px;
    font-size: 15px;
    line-height: 24px;
    margin-left: 30px;
    margin-right: 30px;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButtonRow {
  margin-bottom: 32px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceButtonStylesChevronWhite;
  composes: animation;

  background-color: var(--marketplaceColorHeroButton);

  @media (--viewportMedium) {
    display: block;
    width: 360px;
  }

  @media (max-width: 767px) {
    min-height: 46px;
    padding-top: 12px;
    padding-bottom: 0;
  }
}

.heroButtonSecondary {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceButtonStylesChevronPurple;
  composes: animation;

  color: var(--marketplaceColorHeroButton);
  border-color: var(--marketplaceColorHeroButton);

  @media (--viewportMedium) {
    display: block;
    margin-right: 20px;
    margin-bottom: 0;
    width: 360px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    min-height: 46px;
    padding-top: 9px;
  }
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.imageLogo {
  vertical-align: bottom;
}

.heroImage {
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, transparent 100%);
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 80%, transparent 100%);
}
