@import '../../../styles/propertySets.css';

.root {
  padding-bottom: 84px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }
}

.content {
  @media (--viewportMedium) {
    max-width: 45%;
    margin-right: 10px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  letter-spacing: 0.03em;

  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 30px;
  }
}

.illustration {
  margin-bottom: -10px;
  text-align: center;

  & img {
    max-width: 100%;
  }
}

.text1 {
  color: var(--marketplaceColor);
  font-size: 23px;
  font-weight: var(--fontWeightRegular);
  line-height: 34px;
  margin-top: 0;
  margin-bottom: 28px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 40px;
  }
}

.text2 {
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
}
