@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  border-radius: var(--borderRadiusSectionsMobile);
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: auto;
  padding: 0;
  z-index: 1;

  @media (--viewportMedium) {
    min-height: 500px;
    min-height: 550px;
    margin: 0 20px;
  }

  @media (--viewportLarge) {
    border-radius: var(--borderRadiusSections);
    /* max-height: 800px; */
    max-height: 550px;
  }
}

.heroRoot {
  background-color: var(--marketplaceColorPalePeach);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportMedium) {
    background: url('./SectionHero/hero-img-new.png') no-repeat;
    background-color: var(--marketplaceColorPalePeach);
    background-repeat: no-repeat;
    background-position-x: calc(50% + 415px);
    background-position-y: bottom;
  }

  /* @media (--viewportXLarge) {
    background-position-x: 70%;
  } */
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-top: 32px;
  padding-bottom: 28px;

  @media (--viewportMedium) {
    justify-content: flex-start;
    padding-bottom: 100px;
  }

  @media (--viewportLarge) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.sectionTopMargin {
  margin-top: calc(var(--LandingPage_sectionOffset) * -1);
}

.introRoot {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);
  composes: sectionTopMargin;
  padding: 94px 0;
  margin-left: 20px;
  margin-right: 20px;
}

.categoriesRoot,
.newListingsRoot,
.howItWorksRoot,
.communityRoot {
  background-color: var(--successColorLight);
  border-radius: var(--borderRadiusSectionsMobile);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.09);
  composes: sectionTopMargin;
  overflow: hidden;

  @media (--viewportMedium) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (--viewportLarge) {
    border-radius: var(--borderRadiusSections);
  }
}

.categoriesRoot {
  background-color: #fffff4;
  z-index: 1;
}

.newListingsRoot {
  background-color: var(--marketplaceColorPaleGreen);
  z-index: 1;

  & .sectionContent {
    @media (min-width: 1300px) {
      max-width: 1180px;
      padding: 0;
    }
  }
}

.howItWorksRoot {
  background-color: var(--infoColorLight);
  z-index: 1;
}

.communityRoot {
  background-color: white;
  margin-bottom: 95px;
  z-index: 1;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 40px 60px 40px;
  }

  @media (--viewportLarge) {
    max-width: 1132px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}
