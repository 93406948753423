@import '../../../styles/propertySets.css';

.root {
  padding-bottom: 60px;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  letter-spacing: 0.03em;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 28px;
  }

  @media (--viewportLarge) {
    font-size: 32px;
  }
}

.listings {
  @media (--viewportXSmall) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
  }

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.listing {
  margin-top: 25px;

  @media (--viewportXSmall) {
    margin-top: 0;
  }
}
