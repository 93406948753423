@import '../../../styles/propertySets.css';

.root {
  border-radius: var(--borderRadiusSectionsMobile);
  overflow: hidden;

  padding: 80px 0;
  position: relative;

  @media (--viewportLarge) {
    border-radius: var(--borderRadiusSections);
  }
}

.background {
  background-image: var(--marketplaceColorGradient), url('../../../assets/crops_pattern.png');
  background-color: var(--successColor);
  background-size: cover, 120px;
  background-repeat: no-repeat, repeat;
  background-position: top left;

  filter: blur(4px);

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &::after {
    content: '';
    background: rgba(62, 76, 66, 0.65);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
  margin: 0 auto;
  max-width: 750px;
  padding: 0 20px;
  position: relative;
  text-align: center;
  z-index: 1;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.logo {
  margin: 0 auto 26px;
  width: 164px;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: normal;

  @media (--viewportMedium) {
    font-size: 27px;
    line-height: 37px;
  }
}
