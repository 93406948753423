@import '../../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  letter-spacing: 0.03em;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 28px;
  }

  @media (--viewportLarge) {
    font-size: 32px;
  }
}

.filteredSearches {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportXSmall) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-top: 10px;
  }

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 40px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
}

.searchLink {
  width: 100%;
  margin-top: 25px;

  @media (--viewportXSmall) {
    margin-top: 10px;
    height: 160px;
  }

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

@media (--viewportMedium) {
  .searchLink {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 24px;
  transition: var(--transitionStyleButton);

  @media (--viewportXSmall) {
    height: 160px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.searchImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;

  @media (--viewportXSmall) {
    word-break: break-all;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (--viewportLarge) {
    font-size: 22px;
  }
}

.searchName {
  color: white;
}

.browseAll {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceButtonStylesChevronGreen;
  margin: 80px auto 130px;
  max-width: 488px;

  @media (--viewportMedium) {
    border-width: 3px;
  }

  @media (--viewportLarge) {
    margin: 100px auto 116px;
  }
}

.imageOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  padding: 13px 35px;
  z-index: 1;

  @media (--viewportXSmall) {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
