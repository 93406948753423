@import '../../../styles/propertySets.css';

.root {
  padding-bottom: 84px;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColor);
  letter-spacing: 0.03em;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 32px;
  }

  @media (--viewportLarge) {
    font-size: 40px;
  }
}

.illustration {
  margin-bottom: -10px;

  & img {
    max-width: 100%;
  }
}

.steps {
  counter-reset: step;
  margin-bottom: 46px;

  @media (--viewportMedium) {
    display: flex;
    justify-content: space-between;
  }
}

.step {
  margin-bottom: 30px;
  text-align: center;

  &::before {
    counter-increment: step;
    content: counter(step);
    color: var(--marketplaceColorLight);
    font-size: 28px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 32px;
  }

  @media (--viewportMedium) {
    width: 310px;
    margin-right: 40px;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
    &::before {
      font-size: 36px;
      line-height: 38px;
    }
  }

  & p {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    line-height: 24px;

    @media (--viewportLarge) {
      font-size: 16px;
      line-height: 30px;
    }
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  color: var(--marketplaceColor);
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 28px;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 32px;
  }

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 40px;
  }
}

.hint {
  background-color: var(--matterColorLight);
  border-radius: 47px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
  padding: 30px;

  font-size: 14px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 16px 55px 16px 40px;

    & span {
      padding-left: 40px;
    }

    & svg {
      flex: 0 0 23px;
    }
  }

  @media (--viewportLarge) {
    font-size: 16px;
    line-height: 32px;
  }

  & svg,
  & span {
    display: block;
  }
}

.signupButton {
  @apply --marketplaceButtonStylesPrimary;
  @apply --marketplaceButtonStylesChevronWhite;
  max-width: 386px;
  width: 100%;
  text-align: center;
}

.signupButtonWrapper {
  text-align: center;
  display: flex;
  justify-content: center;
}
